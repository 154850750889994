import React from "react"
import PropTypes from "prop-types"
import MenuItem from "../components/MenuItem"
import { useStaticQuery, graphql } from "gatsby"


function AppartementsMenu({active}) {
  const { allServicesYaml } = useStaticQuery(graphql`
    {
      allServicesYaml {
        edges {
          node {
            title
            description
            link
            image {
              childImageSharp {
                resize(width: 100, height:100) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div id = "services" className="container-fluid" style={{ backgroundColor: "#FFCD6C" }}>
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-5">Unsere Ferienwohnungen</h1>
          <div className="row">
            {allServicesYaml.edges.map(( e, index ) => {
              const node = e.node;
              return <MenuItem title={node.title} src={node.image.childImageSharp.resize.src} description={node.description} link={node.link} round={true} active={index + 1 === active } key={index}/>
            })}
          </div>
          <div className="mt-2 mb-2">
          <p>Unser Appartementhaus liegt ruhig und sonnig, mit <strong>herrlicher Aussicht auf die umliegende Bergwelt</strong>. Wir bieten Ihnen drei komplett ausgestattete Ferienwohnungen für 2 bis 6 Personen, 
            mit Vorraum, gemütlicher Wohnküche, separatem Wohnzimmer mit SAT-TV, kostenlose WLAN-Benutzung, Dusche und WC getrennt, Föhn sowie Balkon. Brötchenservice, Tischtennis, große Liegewiese mit Gartenmöbeln
            und Gartengrill stehen genauso wie Schuhtrockner, Schlittenverleih, Abstellraum für Sportgeräte und ein hauseigener Parkplatz zu Ihrer Verfügung.
          </p>
          </div>
        </div>
      </div>
    </div>
  )
}

AppartementsMenu.propTypes = {
  active: PropTypes.number,
}


export default AppartementsMenu
