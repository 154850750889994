import React from "react"
import "../global.css"
import Highlights from "../subpages/Highlights"
import End from "../subpages/End";
import AppartementMenu from "../components/AppartementMenu";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SeoHelmet from "../components/seo"

function Ferienwohnungen() {
  return (
    <div>
      <SeoHelmet 
        title="Appartement in Werfenweng - Haus Gruber Ferienwohnungen"
        description="Das Haus Gruber Ferienwohnungen bietet Ihnen geräumige und gemütlich eingerichtete Appartements. Wir freuen uns auf Ihren Besuch!"
      />
      <Header height={60}/>
      <AppartementMenu />
      <Highlights />
      <End/>
      <Footer/>
    </div>
  )
}

export default Ferienwohnungen
