import React from "react"
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image"

const HighlightsWrap = styled.div`
  background-color: #6BA6FF;
  color: white;
  a {
    color: white;
  }
`;

function Highlights() {
  return (
    <HighlightsWrap id="highlights" className="container-fluid">
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-5">Unsere Highlights</h1>
          <div className="row no-gutters position-relative">
            <div className="col-md-6 mb-4 p-md-4">
              <StaticImage
                src="../images/highlights/DSC_4464.jpg"
                alt="Entspannung pur! Genießen Sie unsere Sauna!"
                className="w-100"
                placeholder="blurred"
                layout="fixed"
                width={400 }
                height={267}
              />
            </div>
            <div className="col-md-6 p-md-4">
              <h5 className="mt-0">Entspannung pur! Genießen Sie unsere Sauna!</h5>
              <p>Im Untergeschoß unseres Hauses können unsere Gäste im Saunabereich auf Wärmeliegen entspannen. Revitalisierung für Körper,
              Geist und Seele garantieren wohlige Wärme oder dampfende Entspannung. Sie können sich in unserem Wellnessbereich mit einer Massage auf Vorbestellung verwöhnen lassen.</p>
            </div>
          </div>
          <div className="row no-gutters position-relative">
            <div className="col-md-6 mb-4 p-md-4 order-md-last">
            <StaticImage
                src="../images/highlights/DSC_4459.jpg"
                alt="Ein Rückzugsort für die Kinder"
                className="w-100"
                placeholder="blurred"
                layout="fixed"
                width={400}
                height={267}
              />
             </div>
            <div className="col-md-6 p-md-4">
            <h5 className="mt-0">Ein Rückzugsort für die Kinder</h5>
              <p>Unser Haus ist ideal für mehrere Familien mit Kindern die bei uns gemeinsam ihren Urlaub verbringen wollen. Im Untergeschoß unseres Hauses können sich unsere kleinen Gäste im Spielzimmer austoben.</p>
            
            </div>
          </div>
          <div className="row no-gutters mb-5">
            <div className="col-md-6 mb-4 p-md-4">
              <StaticImage
                src="../images/highlights/DSC_4476.jpg"
                alt="Tischtennis"
                className="w-100"
                placeholder="blurred"
                layout="fixed"
                width={400}
                height={267}
              />
            </div>
            <div className="col-md-6 position-static p-md-4">
              <h5 className="mt-0">Tischtennis</h5>
                <p>Als Aktivität für alle Altersklassen finden sie in unserem Haus auch einen Tischtennistisch. Nicht nur bei schlechtem Wetter ist hier für Spaß und Spannung gesorgt. Entsprechendes Equipment ist natürlich auch vorhanden. Game, Set und Match!</p>
            </div>
          </div>
        </div>
      </div>
    </HighlightsWrap>
  )
}

export default Highlights