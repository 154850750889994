import React from "react"
import Fade from "react-reveal/Fade"

function MenuItem({ src, title, link, size, round, active}) {
  const containerClass = `col-lg-${size ? size : 4} my-4 col-sm-12 text-center ${active ? 'disabled' : ''}`;
  const imgClass =  round ? 'img-fluid rounded-circle' : 'img-fluid';
  return (
    <Fade bottom>
      <div className={containerClass}>
        {src && <img src={src} className={imgClass} alt={title} title={title} />}
        <h4 className="mt-3">{title}</h4>
        {link && <a href={link}>Mehr Information</a>}
      </div>
    </Fade>
  )
}

export default MenuItem
